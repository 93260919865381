import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import BackgroundImage from "../components/background-image";
import BGImage from "../images/hiring-page-image.jpg"
import BGImageM from "../images/forest-m.jpg"
import ImageGallery from "../components/image-gallery";
import CtaBar from "../components/cta-bar";
import {Helmet} from "react-helmet";

const Hiring = () => {
    return(
        <main>
            <Helmet>
                <title>Rancho Bernardo Maids - Hiring</title>
                <meta name="description" content="Rancho Bernardo Maids offers well paying and consistent maid jobs, apply today!" />
                <meta name="keywords" content="housekeeper job, maid job, maid hire"/>
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Nav/>
            <BackgroundImage image={BGImage} mobileImage={BGImage} alt={"forest background"} height={"h-128"}hero={true}>
                <h1 className={"text-8xl text-center text-white p-6"}>Hiring</h1>
            </BackgroundImage>
<div className={"dark:bg-gray-900 dark:text-white"}>
            <div className={"py-16 flex flex-col justify-center max-w-lg mx-auto "}>
                <h1 className={"text-center font-bold text-3xl text-malibu-500 p-5"}>Looking for a Well Paid Position?</h1>
                <p className={"leading-loose pb-4"}>We are hiring people with positive attitudes  with house cleaning experience. We have two positions available.  A cleaning crew member and cleaning crew lead. </p>
                <p className={"leading-loose pb-4"}><span className={"font-bold"}>Cleaning crew members pay $18- $21 an hour.</span> We are looking for people with a minimum of 6 months to 1 yr cleaning experience. Cleaning crew members are responsible for cleaning any and all assigned sections of the house and report to a crew lead.

                </p>
                <p className={"leading-loose pb-4"}><span className={"font-bold"}>Cleaning crew leads pays between $22-$25 an hour</span>. We are looking for people with a minimum of 6 months to 1 yr cleaning experience ideally we/ experience leading a cleaning crew. Cleaning crew leaders are responsible for making sure the job gets completed. Cleaning crew leads need a valid driver's license and responsible for driving the crew members to their jobs. </p>
                <p className={"leading-loose pb-4"}>
                    If you're interested in a position,
                    please email your contact info
                    to: <a className={"text-malibu-700"} href={"mailto:mari.rbmaids@gmail.com"}>mari.rbmaids@gmail.com</a>.
                    Please include: A resume or a list
                    of references, when you're
                    available for an interview, and
                    when you could start working.</p>
            </div>
</div>

            <div className="max-w-2xl my-8 p-8 px-8 max-w-[40rem] border-2 border-main-color-500 rounded mx-auto">
            <form name={"hiring"} method={"POST"} data-netlify={"true"}>
                <p className={"text-center text-xl"}>Fill out the form below to apply!</p>
                <input type="hidden" name="form-name" value="hiring" />
                <div className="relative z-0 mb-6 w-full group">
                    <input type="text" name="name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                    <label htmlFor="name" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                    <input type="email" name="email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                    <input type="tel" name="phone" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="phone" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                    <input type="text" name="resume" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label htmlFor="resume" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Resume</label>
                </div>

                <div className={"flex justify-center"}>
                    <button type={"submit"} className={"bg-blue-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Submit
                    </button>
                </div>
            </form>
            </div>
            <Footer/>
        </main>
    )
}

export default Hiring