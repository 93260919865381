import * as React from 'react'
import Button from "./button";

function CtaBar(){
    return(
        <div className={" px-4 py-16 bg-malibu-500 dark:bg-malibu-700"}>
            <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                <h1 className={"text-center text-2xl text-white font-bold sm:text-3xl max-w-lg"}>Need To Hire An Experienced Cleaning Crew?</h1>
                <Button style={"bg-white text-black mt-4 sm:mt-0 sm:ml-4"} to={"/contact"}>Request Free Estimate</Button>
            </div>
        </div>
    )
}
export default CtaBar